import { useState } from 'react';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { Container, Card, CardContent, CardActions, IconButton } from '@mui/material';
import { ContentCopy, GitHub, Instagram, LinkedIn, MarkEmailUnread } from '@mui/icons-material';
import NotificationService from '../../services/NotificationService';

import MenuBar from '../../components/menu-bar/MenuBar';

export default function ContactPage() {

    const [drawerOpen, setDrawerOpen] = useState(false);

    const clickMenuBar = () => {
        setDrawerOpen(!drawerOpen);
    }

    const resetDrawerOpen = () => {
        setDrawerOpen(false);
    }

    const onEmailClick = () => {
        NotificationService.notifyInfo('Email Copied to Clipboard');
        navigator.clipboard.writeText('kylefischer1995@gmail.com');
    }

    return (
        <>
            <Helmet>
                <title>Contact Me</title>
            </Helmet>
            <MenuBar title="Contact Me" onButtonPress={clickMenuBar} />
            <NavigationBar drawerOpen={drawerOpen} onDrawerClose={resetDrawerOpen} />
            <br />
            <Container >
                <Card>
                    <CardContent>
                        <h1>Contact Me</h1>
                        <p>I am always down to meet new people so wether you got a question about a project I did, trip I went on, or just want to chill and talk about video games, reach out!</p>
                        <p>Feel free to contact me at my email address: <a href="mailto:kylefischer1995@gmail.com">KyleFischer1995@gmail.com</a> 
                            <IconButton onClick={onEmailClick}>
                                <ContentCopy/>
                            </IconButton>
                        </p>
                    </CardContent>
                    <CardActions>
                        <IconButton href='https://www.github.com/kylejfischer/' target="_blank" rel="noopener noreferrer">
                            <GitHub/>
                        </IconButton>
                        <IconButton href='https://www.linkedin.com/in/kylejamesfischer/' target="_blank" rel="noopener noreferrer">
                            <LinkedIn/>
                        </IconButton>
                        <IconButton href='https://www.instagram.com/kylejamesfischer/' target="_blank" rel="noopener noreferrer">
                            <Instagram/>
                        </IconButton>
                        <IconButton href='mailto:kylefischer1995@gmail.com'>
                            <MarkEmailUnread />
                        </IconButton>
                    </CardActions>
                </Card>
            </Container>

        </>
    );
}