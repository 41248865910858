import { useState } from 'react';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { Container, Card, Typography, CardHeader, CardContent } from '@mui/material';

import MenuBar from '../../components/menu-bar/MenuBar';

export default function PlaygroundPage() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const clickMenuBar = () => {
        setDrawerOpen(!drawerOpen);
    }

    const resetDrawerOpen = () => {
        setDrawerOpen(false);
    }

    return (
        <>
            <Helmet>
                <title>FischTank Playground</title>
            </Helmet>
            <MenuBar title="Playground" onButtonPress={clickMenuBar} />
            <NavigationBar drawerOpen={drawerOpen} onDrawerClose={resetDrawerOpen} />
            <br />
            <Container >
                <Card>
                    <CardHeader title="Welcome to the Playground!" />
                    <CardContent>
                        <Typography variant='p'>
                            This is where I will be putting my projects that have a live demo. Some of my projects involving hardware might not be available to post here. But things such as the Whopper Compass will hopefully be here soon.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}