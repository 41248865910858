import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { AccountCircle, Home, Science, Toys } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from './NavigationConfig'; 

NavigationBar.propTypes = {
    drawerOpen: PropTypes.bool,
    onDrawerClose: PropTypes.func,
};

export default function NavigationBar({ drawerOpen, onDrawerClose }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        setOpen(drawerOpen);
    }, [drawerOpen]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        if (!newOpen) {
            onDrawerClose();
        }
    };

    const getIcon = (iconText) => {
        switch (iconText) {
            case 'Account':
                return <AccountCircle />;
            case 'home':
                return <Home />;
            case 'project':
                return <Science />;
            case 'playground':
                return <Toys />;
            default:
                return <InboxIcon />;
        }
    }

    const onNavButtonClick = (path, value) => {
        console.log(path, value);
        navigate(path);
    }


    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {NavigationConfig.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton onClick={() => onNavButtonClick(item.path)}>
                            <ListItemIcon>
                                {getIcon(item.icon)}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Contact Me'].map((text, index) => (
                    <ListItemButton onClick={() => onNavButtonClick("/contact")}>
                        <ListItemIcon>
                            {getIcon(text)}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}