import { toast } from 'react-toastify';

export default class NotificationService {
    static notifySuccess(message) {
        toast.success(message);
    }

    static notifyError(message) {
        toast.error(message);
    }

    static notifyInfo(message) {
        toast.info(message);
    }

    static notifyWarn(message) {
        toast.warn(message);
    }
}
