import { Navigate, useRoutes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import ProjectsPage from './pages/projects/ProjectsPage';
import PlaygroundPage from './pages/playground/PlaygroundPage';
import ContactPage from './pages/contact/ContactPage';
export default function Router() {
    const routes = useRoutes([
        {
            path: '*',
            element: <Navigate to="/home" replace />,
        },
        {
            path: '/home',
            element: <HomePage />,
        },
        {
            path: '/projects',
            element: <ProjectsPage />,
        },
        {
            path: '/playground',
            element: <PlaygroundPage />,
        },
        {
            path: '/contact',
            element: <ContactPage />,
        }
    ]);

    return routes;
}