import { useState } from 'react';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';

import MenuBar from '../../components/menu-bar/MenuBar';

export default function ProjectsPage() {

    const [drawerOpen, setDrawerOpen] = useState(false);

    const clickMenuBar = () => {
        setDrawerOpen(!drawerOpen);
    }

    const resetDrawerOpen = () => {
        setDrawerOpen(false);
    }

    return (
        <>
            <Helmet>
                <title>Fisch Tank Projects</title>
            </Helmet>
            <MenuBar title="FischTank" onButtonPress={clickMenuBar} />
            <NavigationBar drawerOpen={drawerOpen} onDrawerClose={resetDrawerOpen} />
            <br />
            <Container >

            </Container>

        </>
    );
}