import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Card, CardHeader, CardContent, ListItemIcon, ListItemText} from '@mui/material';
import { CheckCircle, Adjust } from '@mui/icons-material';
import PropTypes from 'prop-types';

TodoCard.propTypes = {
    title: PropTypes.string,
    todos: PropTypes.array,
};

export default function TodoCard({ title, todos }) {
    return (
        <Card>
            {title && <CardHeader title={title} />}
            <CardContent>
                <List>
                    {todos.map((todo) => (
                        <ListItem key={todo.text}>
                            <ListItemIcon>
                                {todo.completed ? <CheckCircle /> : <Adjust />}
                            </ListItemIcon>
                            <ListItemText primary={todo.text} />
                        </ListItem>
                    ))}
                </List>
                </CardContent>
        </Card>
    );
}