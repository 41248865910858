import { useState } from 'react';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Card } from '@mui/material';

import MenuBar from '../../components/menu-bar/MenuBar';
import TodoCard from '../../components/todo-card/TodoCard';
import WhereKyleCard from '../../components/where-kyle-card/WhereKyleCard';

export default function HomePage() {

    const [drawerOpen, setDrawerOpen] = useState(false);

    const clickMenuBar = () => {
        setDrawerOpen(!drawerOpen); 
    }

    const resetDrawerOpen = () => {
        setDrawerOpen(false);
    }

    return (
        <>
            <Helmet>
                <title>Welcome to the FischTank!</title>
            </Helmet>
            <MenuBar title="FischTank" onButtonPress={clickMenuBar}/>
            <NavigationBar drawerOpen={drawerOpen} onDrawerClose={resetDrawerOpen}/>
            <br/>
            <Container >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <Box sx={{p: 2}}>
                                <h1>Welcome to the FischTank!</h1>
                                <p>This is my personal website where I will be posting my projects and other things I find interesting. I hope you enjoy your stay!</p>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        <TodoCard title="Active Project Todos: (7/29/2024)" todos={[
                            {
                                text: "Get Automated CI/CD via Github Actions Completed",
                                completed: true
                            },
                            {
                                text: "Make this site look pretty",
                                completed: false
                            },
                            {
                                text: "Add Projects to Project Page"
                            },
                            {
                                text: "Make Whopper Compass Live Demo in the Playground",
                            }
                        ]}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WhereKyleCard/>
                    </Grid>
                </Grid> 
            </Container>
        </>
    );
}