export const NavigationConfig = [
    {
        title: 'Home',
        path: '/',
        icon: "home",
    },
    {
        title: 'Projects',
        path: '/projects',
        icon: "project",
    },
    {
        title: 'Playground',
        path: '/playground',
        icon: "playground",
    }
];