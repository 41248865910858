import * as React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

WhereKyleCard.propTypes = {
    title: PropTypes.string,
    todos: PropTypes.array,
};

export default function WhereKyleCard() {
    const timeZone = "Eastern Time Zone";
    // const tzFormat = "America/Indiana/Indianapolis";

    //Todo - Get the current time zone from my API on Jerry
    return (
        <Card>
            <CardHeader title="Kyle's Current TimeZone" />
            <CardContent>
                <p>Kyle is in the {timeZone}!</p>
            </CardContent>
        </Card>
    );
}