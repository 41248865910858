import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
MenuBar.prototype = {
    title: PropTypes.string,
    onButtonPress: PropTypes.func,
}

export default function MenuBar({ title, onButtonPress }) {
    return (
            <AppBar position='static'>
                <Toolbar variant="dense">
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={onButtonPress}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
    );
}
